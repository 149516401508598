/* .bg-light {
  background-color: transparent !important;
  overflow: hidden !important;
}

.navbar {
  background: rgba(0,0,0,0.4) !important;
  background-color: transparent !important;
} */


.navbar {
  transition-duration: 1s;
}

.brand {
  font-size: 2rem;
}

.navbar-light .navbar-brand {
  color: white;
  text-shadow: 2px 2px 5px #888;
}

.navbar-light .navbar-nav .nav-link {
  color: white;
  text-shadow: 2px 2px 5px #888;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-toggler {
  border-color: rgb(255, 255, 255);
} 

